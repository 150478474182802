.clipboard {
  position: relative;
  float: right;
  width: 100%;
  display: none;
}

@media (min-width: 768px) {
  .clipboard {
    display: block;
  }
}

.clipboard .clipboard-button {
  position: absolute;
  top: 0.65rem;
  right: 0.65rem;
  z-index: 10;
  display: block;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  border-radius: 0.25rem;
  font-size: 0.65rem;
}

.clipboard .clipboard-button:hover,
.clipboard .clipboard-button:focus {
  background-color: #000;
  color: #fff;
}
